import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import PublicGiftSelector from "../social-supermarket/pages/gift-selection/PublicGiftSelector"

const Container = styled.div``

interface Props {}

const Clockwise: FC<Props> = () => {
  return <PublicGiftSelector orderKey={"Mzc5Njcx"} />
}

export default Clockwise
